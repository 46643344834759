import { axiosClient } from '@/api/axiosClient';

const getters = {
  getUserSegments: state => routerName => {
    const foundCachedUserSegments = state.cachedUserSegments.find(viewNameKey => viewNameKey[routerName]);
    if (foundCachedUserSegments) {
      return foundCachedUserSegments[routerName];
    }
  }
};

const state = {
  cachedUserSegments: []
};

const actions = {
  async getUserSegments({ commit, state }, opt) {
    const { viewRequiredSegmentRoles, routerName } = opt;
    const foundCachedUserSegments = state.cachedUserSegments.find(viewNameKey => viewNameKey[routerName]);

    if (foundCachedUserSegments) {
      return Promise.resolve(foundCachedUserSegments[routerName]);
    }
    const res = await axiosClient.get(`/unit/`);
    const units = res.data.filter(unit => viewRequiredSegmentRoles.some(role => role.unit === unit.name));

    const details = await Promise.all(units.map(unit => axiosClient.get(`/unit/${unit.id}/segments`)));

    const idxs = viewRequiredSegmentRoles.map(item => {
      let foundIdx = units.findIndex(unit => item.unit === unit.name);
      if (foundIdx > -1) {
        return { idx: foundIdx, id: item.name, title: units[foundIdx].title };
      }
    });

    const userSegments = idxs.map(item => {
      let found = details[item.idx].data.find(data => data.name === item.id);
      return { ...found, text: `${found.title}(${item.title})` };
    });
    commit('SET_USER_SEGMENTS', Object.defineProperty({}, routerName, { value: userSegments }));
    return userSegments;
  }
};
const mutations = {
  SET_USER_SEGMENTS(state, userSegments) {
    state.cachedUserSegments.push(userSegments);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
