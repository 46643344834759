import axios from 'axios';
import {
  requestHandler,
  requestErrorHandler,
  responseHandler,
  responseErrorHandler
} from './axiosInterceptor';


const config = {
  baseURL: process.env.VUE_APP_API_URL,
  handlerEnabled: true
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  request => requestHandler(request),
  error => requestErrorHandler(error)
);
axiosClient.interceptors.response.use(
  request => responseHandler(request),
  error => responseErrorHandler(error)
);

export { axiosClient };
