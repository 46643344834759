<template>
  <v-menu :close-on-content-click="closeOnContentClick" :nudge-width="width" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        height="40"
        color="black lighten-2"
        class="caption font-weight-medium blue-grey--text"
        :class="{ 'switcher switcher_hidden': !label }"
        :loading="loading"
        text
        depressed
        v-on="on"
        :disabled="disabled"
      >
        <slot></slot>
      </v-btn>
    </template>
    <slot name="list"></slot>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      default: '100'
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
.switcher_hidden {
  display: none;
}

@media screen and (max-width: 700px) {
  .switcher {
    display: flex;
  }
}
</style>
