import { getFilterOption, IfFormatFilterFeatures } from '../helpers/store-helpers';
import { axiosClient } from '@/api/axiosClient';

const state = {
  cachedFeatures: [],
  cachedDatasets: undefined,
  datasetItemIsSelected: false,
  textfilterSearch: false,
  formatFilter: [],
  orderAreaFilter: []
};

const actions = {
  async search({ commit }, opt) {
    const { roles } = opt;
    const format = state.formatFilter;
    const text = state.textfilterSearch;
    const area = state.orderAreaFilter.map(item => item.id);

    let param = getFilterOption(roles, format, area);

    if (text) {
      param.params.q = text;
    }

    const response = await axiosClient.get(`/search/`, param);
    commit('CACHE_DATA_SETS', [...response.data]);
    return [...response.data];
  },

  async getDatasetFeaturesById({ commit, state }, opt) {
    const { datasetId, format } = opt;

    const matchedDataset = state.cachedFeatures.find(dataset => dataset.datasetId === datasetId);

    if (matchedDataset) {
      console.log('features from cache ...');
      return Promise.resolve(IfFormatFilterFeatures(state.formatFilter, matchedDataset));
    }

    const response = await axiosClient.get(`/dataset/${datasetId}/features`);

    const features = response.data.map(item => ({
      ...item,
      datasetId: datasetId,
      format: item.format && item.format.toLowerCase()
    }));

    commit('CACHE_FEATURES', {
      datasetId,
      features
    });
    return IfFormatFilterFeatures(state.formatFilter, {
      datasetId,
      features
    });
  }
};

const mutations = {
  CACHE_DATA_SETS(state, obj) {
    state.cachedDatasets = obj;
  },

  CACHE_FEATURES(state, obj) {
    state.cachedFeatures.push(obj);
  },
  SET_FORMAT_FILTER(state, format) {
    state.formatFilter = format;
  },
  SET_ORDER_AREA_FILTER(state, orderArea) {
    state.orderAreaFilter = orderArea;
  },

  DATASET_ITEM_SELECTION_CHANGED(state, isSel) {
    state.datasetItemIsSelected = isSel;
  },
  TEXTFILTER_SEARCH(state, value) {
    state.textfilterSearch = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
