<template>
  <div>
    <the-copyright @openTermOfUse="dialog = true" :title="title"></the-copyright>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <component :is="currentTermOfUse"></component>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    currentView: {}
  },
  components: {
    TheCopyright: () => import('@/components/TheCopyright.vue'),
    TermOfUseWasserwirtschaft: () => import('@/components/TermOfUseWasserwirtschaft.vue'),
    TermOfUseWindenergie: () => import('@/components/TermOfUseWindenergie.vue'),
    TermOfUseSolarenergie: () => import('@/components/TermOfUseSolarenergie.vue'),
    TermOfUseLaerm: () => import('@/components/TermOfUseLaerm.vue')
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    currentTermOfUse() {
      return 'TermOfUse' + this.currentView;
    }
  }
};
</script>
