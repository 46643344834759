import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { role_names } from '@/config';
import { omit } from 'lodash';

const LOGIN_SERVICE = `${process.env.VUE_APP_API_URL}/cas/login`;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: {
        authRequired: true,
        roles: [],
        hideSearchPanel: true,
        title: 'Geodatenbereitstellung'
      }
    },

    {
      path: '/wind-energy',
      name: 'wind-energy',
      component: () => import('./views/energy/Wind.vue'),
      meta: {
        authRequired: true,
        roles: [role_names.WIND_ENERGY.value],
        formatTypes: ['GML', 'Shape'],
        title: 'Windenergie'
      }
    },

    {
      path: '/water-management',
      name: 'water-management',
      component: () => import('./views/water/Management.vue'),
      meta: {
        authRequired: true,
        roles: [role_names.WATER_MANAGEMENT.value],
        formatTypes: ['GML', 'Shape', 'Excel'],
        title: 'Wasserwirtschaft'
      }
    },

    {
      path: '/noise-result',
      name: 'noise-result',
      component: () => import('./views/noise/ResultData.vue'),
      meta: {
        authRequired: true,
        roles: [role_names.NOISE_DATA.value],
        formatTypes: ['Shape', 'ASCII-GRID', 'ZIP'],
        title: 'Laerm'
      }
    },
    {
      path: '/download-center',
      name: 'downloadCenter',
      component: () => import('./views/DownloadCenter.vue'),
      meta: {
        authRequired: true,
        roles: ['public'],
        hideSearchPanel: true,
        title: 'Downloadcenter'
      }
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: () => import('./views/Impressum.vue'),
      meta: {
        authRequired: true,
        roles: ['public'],
        hideSearchPanel: true,
        title: 'Impressum'
      }
    },
    {
      path: '/accessibility',
      name: 'accessibility',
      component: () => import('./views/Accessibility.vue'),
      meta: {
        authRequired: true,
        roles: ['public'],
        hideSearchPanel: true,
        title: 'Barrierefreiheit'
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy.vue'),
      meta: {
        authRequired: true,
        roles: ['public'],
        hideSearchPanel: true,
        title: 'Datenschutz'
      }
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('./views/Error.vue')
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('./views/Forbidden.vue'),
      meta: {
        hideSearchPanel: true,
        title: 'Geodatenbereitstellung'
      }
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import('./views/NotFound.vue'),
      props: true
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some(route => route.meta.authRequired);
  const routerRequiredRoles = to.matched.map(route => route.meta.roles || [])[0] || [];

  const currentUser = store.state.auth.user;

  if (!authRequired) {
    return next();
  } else if (authRequired && currentUser) {
    if (!currentUser.hasRequiredRoles(routerRequiredRoles)) {
      return next({ name: '' });
    }
    return next();
  } else {
    let token = !!to.hash && to.hash.split('#');

    if (token[1]) {
      token = token[1];
      store.commit('auth/SET_SESSION_TOKEN', token);
      const isAuth = await store.dispatch('auth/authenticate');
      if (!isAuth) {
        return next({ name: 'forbidden' });
      }
      next({
        ...omit(to, ['hash']),
        replace: true
      });
    }

    let service = window.location.origin + to.path;
    // if (process.env.NODE_ENV === 'production') {
    //   //service = window.location.origin + '/geodatenbereitstellung' + to.path;
    //   //service = window.location.origin + '/' + to.path;
    // }

    if (currentUser) {
      return next();
    }

    if (!token) {
      window.location.replace(`${LOGIN_SERVICE}?relayState=${service}`);
      return;
    }
  }
});

export default router;
