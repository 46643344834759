import Vue from 'vue';
import Vuex from 'vuex';
import dataSet from './modules/dataSet';
import order from './modules/order';
import auth from './modules/auth';
import orderArea from './modules/orderArea';
import notifications from './modules/notifications';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dataSet,
    order,
    auth,
    orderArea,
    notifications
  }
});
export default store;
