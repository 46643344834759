<template>
  <v-menu-list :disabled="disabled" :loading="loading" :label="label">
    {{ label }}
    <v-badge :content="orderAreaFilter.length" :value="orderAreaFilter.length" color="geoportal" bordered>
      <v-icon right>mdi-menu-down</v-icon>
    </v-badge>
    <template v-slot:list>
      <v-list dense flat color="grey lighten-2" max-height="200" class="overflow-y-auto">
        <v-list-item>
          <v-text-field
            hide-details
            class="mb-1"
            autocomplete="false"
            placeholder="Filter"
            background-color="grey lighten-2"
            clearable
            v-model="searchTerm"
            color="geoportal"
          >
          </v-text-field>
        </v-list-item>
        <template v-for="(item, i) in values">
          <v-list-item :key="i" :input-value="isActive(item)" @click="toggle(item)">
            <v-list-item-content>
              <div class="v-menu-list-item__subtitle" v-if="searchTerm">
                <template v-if="getIndexOfSearchTerm(item.text) === 0">
                  <span class="v-menu-list-item__mask">{{ substrText(item.text, 0, searchTerm.length) }}</span>
                  <span>{{ substrText(item.text, searchTerm.length, item.text.length) }}</span>
                </template>
                <template v-if="getIndexOfSearchTerm(item.text) > 0">
                  <span>{{ substrText(item.text, 0, getIndexOfSearchTerm(item.text)) }}</span>
                  <span class="v-menu-list-item__mask">{{
                    substrText(item.text, getIndexOfSearchTerm(item.text), searchTerm.length)
                  }}</span>
                  <span>{{
                    substrText(item.text, getIndexOfSearchTerm(item.text) + searchTerm.length, item.text.length)
                  }}</span>
                </template>
              </div>
              <v-list-item-title v-else v-text="item.text || item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </v-menu-list>
</template>

<script>
import VMenuList from '@/components/VMenuList.vue';

export default {
  components: {
    VMenuList
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    currentUserSegmentsRoles: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      searchTerm: '',
      selected: [],
      loading: true,
      items: undefined,
      selections: 0
    };
  },

  computed: {
    values() {
      if (this.items && this.searchTerm) {
        return this.items.filter(item => item.text.toLowerCase().includes(this.searchTerm.toLowerCase()));
      }
      this.items &&
        this.items.sort((a, b) => {
          const titleA = a.title.toUpperCase();
          const titleB = b.title.toUpperCase();
          let comparison = 0;
          if (titleA > titleB) {
            comparison = 1;
          } else if (titleA < titleB) {
            comparison = -1;
          }
          return comparison;
        });
      return this.items;
    },
    orderAreaFilter() {
      return this.$store.state.dataSet.orderAreaFilter;
    }
  },
  methods: {
    clear() {
      this.selected = [];
      this.$store.commit('dataSet/SET_ORDER_AREA_FILTER', []);
    },
    async getUserSegments() {
      let currentRouterName = this.$route.name;

      if (currentRouterName === 'home' || currentRouterName === 'forbidden') {
        return;
      }
      let viewRequiredRole = this.$route.meta.roles[0];

      let viewRequiredSegmentRoles = this.currentUserSegmentsRoles.filter(segment => segment.role === viewRequiredRole);

      this.items = await this.$store.dispatch('orderArea/getUserSegments', {
        viewRequiredSegmentRoles,
        routerName: currentRouterName
      });

      this.loading = false;
    },

    isActive(item) {
      return this.selected.some(sel => sel.id === item.id);
    },
    toggle(item) {
      const found = this.selected.find(sel => sel.id === item.id);
      if (found) {
        this.selected = this.selected.filter(item => item.id !== found.id);
      } else {
        this.selected.push(item);
      }
      this.selections = this.selected.length;
      this.$store.commit('dataSet/SET_ORDER_AREA_FILTER', this.selected);
      this.$emit('orderAreaSelectionChanged', this.selected);
    },
    getIndexOfSearchTerm(text) {
      return text.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase());
    },
    substrText(text, fromIndex, toIndex) {
      return text.substr(fromIndex, toIndex);
    }
  },
  watch: {
    orderAreaFilter(val) {
      this.selected = val || [];
    },
    '$route.name'(newValue) {
      this.searchTerm = '';
      this.getUserSegments();
    }
  }
};
</script>
<style scoped>
.v-list-item--active {
  color: #afc500;
}
.v-menu-list-item__subtitle {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}

.v-menu-list-item__mask {
  color: rgba(0, 0, 0, 0.38);
  background: #afc500;
}
.switcher_hidden {
  display: none;
}
</style>
