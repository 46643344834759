export const table_headers = [
  {
    text: 'Status',
    align: 'left',
    sortable: false,
    value: 'status'
  },
  { text: 'Produkt(e)', value: 'name' },
  { text: 'Bestellgebiet', value: 'orderArea' },
  { text: 'Downloadgröße', value: 'downloadSize' },
  { text: 'Format', value: 'format' },
  { text: 'Download verwalten', value: 'manage', sortable: false },
  { text: '', value: 'data-table-expand' }
];
export const order_action = {
  download: {
    title: 'Herunterladen',
    color: 'geoportal',
    icon: 'mdi-cloud-download',
    class: 'ma-2 px-4',
    loading: false
  },
  copy: {
    title: 'Downloadlink kopieren',
    color: 'geoportal',
    //icon: 'mdi-content-copy',
    class: 'ma-2 px-4',
    loading: false,
    disabled: undefined
  },
  delete: {
    title: 'Löschen',
    color: 'geoportal',
    icon: 'mdi-delete-off',
    class: 'ma-2 px-4',
    loading: false
  },
  cancel: {
    label: 'Abbrechen',
    title: 'Abbrechen',
    color: 'geoportal',
    class: 'ma-4 px-4',
    loading: false
  },
  reorder: {
    title: 'Aktualisieren',
    color: 'geoportal',
    icon: 'mdi-refresh',
    class: 'ma-2 px-4',
    loading: false
  },
  error: {
    title: 'Fehler',
    color: 'grey',
    icon: 'mdi-alert-circle',
    class: 'ma-2 px-4',
    loading: false,
    disabled: true
  }
};
//order status properties
export const status = {
  new: {
    label: 'wird bestellt...',
    name: 'new',
    color: 'grey'
  },
  processing: {
    label: 'in Produktion',
    name: 'processing',
    color: 'primary'
  },
  ready: {
    label: 'abgeschlossen',
    name: 'ready',
    title: 'Bestellung herunterladen',
    icon: 'mdi-check',
    color: 'success',
    action: 'download'
  },
  empty: {
    label: 'keine Daten',
    name: 'empty',
    color: 'amber',
    icon: 'mdi-alert-circle-outline',
    action: 'error'
  },
  error: {
    label: 'Fehler',
    name: 'error',
    color: 'error',
    icon: 'mdi-alert-circle',
    action: 'error'
  },
  abort: {
    label: 'abgebrochen',
    name: 'abort',
    color: 'amber',
    icon: 'mdi-cancel',
    action: 'reorder'
  },
  delete: {
    label: 'Abgebrochen',
    name: 'delete',
    title: 'gelöscht',
    icon: 'mdi-cancel',
    color: 'red',
    action: 'reorder'
  },
  saved: {
    label: 'gespeichert',
    name: 'saved',
    title: 'gespeicherte Bestellung',
    icon: 'mdi-content-save',
    color: 'success',
    action: 'reorder'
  }
};
//roles properties
export const role_names = Object.freeze({
  WATER_MANAGEMENT: {
    value: 'wasserwirtschaft'
  },
  WIND_ENERGY: {
    value: 'energieatlas'
  },
  SOLAR_ENERGY: {
    value: 'energieatlassolar'
  },
  NOISE_DATA: {
    value: 'laerm'
  }
});

export const roles_list = Object.keys(role_names).map(role => role_names[role].value);

const group_names = Object.freeze({
  ENERGY_ATLAS: 'Energieatlas'
});
// app properties
export const app_routers = [
  {
    id: 0,
    role: 'public',
    title: 'Home',
    icon: 'mdi-home',
    route: '/'
  },
  {
    id: 1,
    role: role_names.WATER_MANAGEMENT.value,
    title: 'Wasserwirtschaft',
    route: '/water-management'
  },
  {
    id: 2,
    role: role_names.WIND_ENERGY.value,
    title: 'Windenergie',
    route: '/wind-energy',
    icon: 'mdi-home'
    //groupName: group_names.ENERGY_ATLAS
  },
  // {
  //   id: 2,
  //   role: role_names.SOLAR_ENERGY.value,
  //   title: 'Solarenergie',
  //   route: '/solar-energy',
  //   groupName: group_names.ENERGY_ATLAS
  // },

  {
    id: 3,
    role: role_names.NOISE_DATA.value,
    title: 'Lärmkartierung',
    route: '/noise-result',
    groupName: group_names.NOISE
  },
  {
    id: 4,
    role: 'public',
    title: 'Download-Center',
    route: '/download-center',
    icon: 'mdi-download-multiple'
  }
];
export const format_icons = {
  pdf: {
    color: 'red',
    mdi: 'mdi-file-pdf-box',
    text: 'PDF'
  },
  shape: {
    color: 'blue-grey',
    mdi: 'mdi-shape-polygon-plus',
    text: 'Shape'
  },
  gml: {
    color: 'blue-grey',
    mdi: 'mdi-xml',
    text: 'GML'
  },
  json: {
    color: 'blue-grey',
    mdi: 'mdi-json',
    text: 'json'
  },
  geojson: {
    color: 'teal',
    mdi: 'mdi-json',
    text: 'json'
  },
  csv: {
    color: 'green',
    mdi: 'mdi-file-delimited-outline',
    text: 'CSV'
  },
  tiff: {
    color: 'blue',
    mdi: 'mdi-file-image-outline',
    text: 'TIFF'
  },
  sqlite: {
    color: 'grey',
    mdi: 'mdi-database',
    text: 'SQLite'
  },
  xml: {
    color: 'purple',
    mdi: 'mdi-xml',
    text: 'XML'
  },
  jpeg: {
    color: 'blue-grey',
    mdi: 'mdi-xml',
    text: 'JPEG'
  },
  geodatabase: {
    color: 'blue-grey',
    mdi: 'mdi-xml',
    text: 'Geodatabase'
  },
  datei: {
    color: 'blue-grey',
    //mdi: 'mdi-file-multiple-outline',
    mdi: 'mdi-folder-outline',
    text: 'Daten'
  },
  nas: {
    color: 'blue-grey',
    mdi: 'mdi-file-multiple-outline',
    text: 'NAS'
  },
  zip: {
    color: 'blue-grey',
    mdi: 'mdi-folder-zip-outline',
    text: 'ZIP'
  },
  excel: {
    color: 'blue-grey',
    mdi: 'mdi-file-table-outline',
    text: 'Excel'
  },
  'ascii-grid': {
    color: 'blue-grey',
    mdi: 'mdi-grid',
    text: 'ASCII-GRID'
  },
  access: {
    color: 'blue-grey',
    mdi: 'mdi-microsoft-access',
    text: 'Access'
  }
};
