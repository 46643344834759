import { app_routers as appRouters } from '@/config';

export const getAppRouters = currentUserRoles => {
  return appRouters
    .filter(route => currentUserRoles.includes(route.role))
    .reduce((acc, item) => {
      let key = item.groupName || item.title;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
};
