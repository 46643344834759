<template>
  <v-menu-list :disabled="disabled" :label="label" :width="10">
    <template>
      <v-icon left>mdi-filter-outline</v-icon>
      {{ label }}
      <v-badge :content="formatFilter.length" :value="formatFilter.length" color="geoportal">
        <v-icon right>mdi-menu-down</v-icon>
      </v-badge>
    </template>
    <template v-slot:list>
      <v-list dense flat color="grey lighten-2" max-height="200" class="overflow-y-auto">
        <v-list-item-group color="geoportal" multiple v-model="selected">
          <v-list-item v-for="(item, i) in $route.meta.formatTypes" :key="i" :value="item" @click="change(item)">
            <v-list-item-content>
              <v-list-item-title class="caption font-weight-bold" v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </v-menu-list>
</template>

<script>
import VMenuList from '@/components/VMenuList.vue';
export default {
  components: {
    VMenuList
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    formatFilter() {
      return this.$store.state.dataSet.formatFilter;
    }
  },
  watch: {
    formatFilter(val) {
      this.selected = val || [];
    }
  },

  methods: {
    async change(val) {
      await this.$nextTick();
      this.$emit('formatSelectionChanged', this.selected);
    },
    clear() {
      this.selected = [];
      this.$store.commit('dataSet/SET_FORMAT_FILTER', []);
    }
  }
};
</script>
