import { axiosClient } from '@/api/axiosClient';
import { createUser } from '@/models/User';
import { roles_list } from '@/config';

const state = {
  user: undefined,
  token: undefined
};
const getters = {
  getUser(state) {
    return state.user;
  },
  getUserId(state) {
    return state.user && state.user.userId;
  },
  getUserToken(state) {
    return state.token;
  }
};
const actions = {
  async authenticate({ commit }) {
    const userdata = await axiosClient.get('/me/');
    console.log(userdata.data);
    const user = createUser(userdata.data);

    console.log(user.isAuth(roles_list));
    commit('SET_CURRENT_USER', { user });
    return user.isAuth(roles_list);
  },
  async signOut({ commit, state }, opt) {
    return axiosClient.get(`/logout/`);
  }
};
const mutations = {
  SET_CURRENT_USER(state, { user }) {
    state.user = user;
  },
  SET_SESSION_TOKEN(state, token) {
    state.token = token;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
