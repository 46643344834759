<template>
  <v-row justify="end" no-gutters v-show="!$route.meta.hideSearchPanel">
    <slot v-bind="{ textFilter, formatFilter, orderArea }"></slot>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';

export default {
  data() {
    return {
      debouncedGetAnswer: null
    };
  },
  created() {
    this.debouncedGetAnswer = debounce(this.search, 500);
  },
  methods: {
    textFilter(text) {
      this.$store.commit('dataSet/TEXTFILTER_SEARCH', text);

      this.debouncedGetAnswer();
    },
    formatFilter(format) {
      this.$store.commit('dataSet/SET_FORMAT_FILTER', format);
      this.search();
    },
    orderArea(area) {
      this.$store.commit('dataSet/SET_ORDER_AREA_FILTER', area);
      this.search();
    },
    search() {
      this.$store.dispatch('dataSet/search', {
        roles: this.$route.meta.roles
      });
    }
  }
};
</script>
