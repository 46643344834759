<template>
  <v-app id="app">
    <the-navigation-bar class="navigation"></the-navigation-bar>
    <v-main class="content-container">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TheNavigationBar from './components/TheNavigationBar';
export default {
  name: 'App',
  components: {
    TheNavigationBar
  },
  data: () => ({
    //
  }),

  methods: {}
};
</script>
