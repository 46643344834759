<template>
  <div>
    <template v-if="!user">
      <the-status-progress />
    </template>

    <nav v-else>
      <v-app-bar color="grey lighten-4" app flat>
        <v-app-bar-nav-icon @click="drawer = !drawer" v-show="$route.name !== 'forbidden'"></v-app-bar-nav-icon>
        <v-toolbar-title class="menu-title_hidden body-1 blue-grey--text">{{ themeName }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <dataset-search>
          <template v-slot="{ textFilter, formatFilter, orderArea }">
            <v-col lg="3" md="3" sm="5" xs="12" :class="{ 'menu-item_hidden': !items[0].sel }" class="mt-2 mr-5">
              <v-text-field-search
                ref="datasetTextSearch"
                :disabled="datasetItemSelectionChanged"
                @textSearchChanged="textFilter"
              ></v-text-field-search>
            </v-col>

            <div :class="{ 'menu-item_hidden': !items[1].sel }">
              <menu-list-order-area
                ref="menuListOrderArea"
                :label="'Bestellgebiete'"
                :currentUserSegmentsRoles="userSegments"
                :multiple="true"
                :disabled="datasetItemSelectionChanged"
                @orderAreaSelectionChanged="orderArea"
              ></menu-list-order-area>
            </div>

            <div :class="{ 'menu-item_hidden': !items[2].sel }">
              <menu-list-format-types
                ref="menuListFormat"
                :label="'Formatfilter'"
                :multiple="true"
                :disabled="datasetItemSelectionChanged"
                @formatSelectionChanged="formatFilter"
              ></menu-list-format-types>
            </div>
          </template>
        </dataset-search>

        <menu-list-filter-switcher v-model="items"></menu-list-filter-switcher>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app dark>
        <v-list expand>
          <template v-for="(items, name, index) in userRouters">
            <v-list-item
              v-if="items.length === 1"
              :key="index"
              :to="items[0].route"
              :value="items[0]"
              color="geoportal"
            >
              <v-list-item-title v-text="name"></v-list-item-title>
            </v-list-item>

            <v-list-group v-else no-action :key="index">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="name"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(item, idx) in items" :key="idx" color="geoportal" :to="item.route" :value="item">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
        <v-divider></v-divider>
        <v-list-item @click="logout()">
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item>

        <template v-slot:append>
          <div class="pa-2">
            <v-container>
              <term-of-use v-if="$route.meta.formatTypes" :currentView="$route.meta.title" :title="themeName" />
              <router-link to="/privacy" exact>Datenschutz</router-link> <br />
              <router-link to="/impressum" exact>Impressum</router-link> <br />
              <router-link to="/accessibility" exact>Barrierefreiheit</router-link>
            </v-container>
          </div>
        </template>
      </v-navigation-drawer>

      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="geoportal" @click="toTop">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </nav>
  </div>
</template>

<script>
import { createAppRouters, getAppRouters } from '@/common/app-routers';
import { app_routers as appRouters } from '@/config';

import TermOfUse from '@/components/TermOfUse.vue';
import DatasetSearch from '@/components/DatasetSearch.vue';

import MenuListFilterSwitcher from '@/components/MenuListFilterSwitcher.vue';
import MenuListFormatTypes from '@/components/MenuListFormatTypes.vue';
import MenuListOrderArea from '@/components/MenuListOrderArea.vue';
import VTextFieldSearch from '@/components/VTextFieldSearch.vue';
import TheStatusProgress from '@/components/TheStatusProgress.vue';

const LOGOUT_SERVICE = `${process.env.VUE_APP_API_URL}/cas/logout`;
export default {
  name: 'TheNavigationBar',
  components: {
    DatasetSearch,
    TermOfUse,
    MenuListFilterSwitcher,
    MenuListFormatTypes,
    MenuListOrderArea,
    VTextFieldSearch,
    TheStatusProgress
  },

  data() {
    return {
      items: [
        { text: 'Datendursuchen', sel: true },
        { text: 'Bestellgebiet', sel: false },
        { text: 'Formatfilter', sel: false }
      ],
      loading: true,
      fab: false,
      drawer: false,
      group: null,
      title: undefined
    };
  },
  computed: {
    themeName() {
      const name = this.$route.meta.title;
      return name === 'Laerm' ? 'Lärm' : name;
    },
    user() {
      return this.$store.state.auth.user;
    },
    userSegments() {
      return this.user.segmentRoles;
    },
    userRouters() {
      return getAppRouters(this.user.roles);
    },
    datasetItemSelectionChanged() {
      return this.$store.state.dataSet.datasetItemIsSelected;
    }
  },
  watch: {
    group() {
      this.drawer = false;
    },
    '$route.name'(newValue, oldValue) {
      this.loading = false;
      if (!this.$refs.datasetTextSearch) {
        return;
      }
      this.$refs.datasetTextSearch.clear();
      this.$refs.menuListFormat.clear();
      this.$refs.menuListOrderArea.clear();
      this.$store.commit('dataSet/DATASET_ITEM_SELECTION_CHANGED', false);
      this.$store.commit('dataSet/CACHE_DATA_SETS', undefined);
    }
  },
  methods: {
    logout() {
      window.location.replace(
        `${LOGOUT_SERVICE}?relayState=${window.location.origin}&token=${this.$store.state.auth.token}`
      );
    },
    onScroll(e) {
      if (!window) {
        return;
      }

      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
<style scoped>
.list {
  overflow: auto;
}
@media screen and (max-width: 1000px) {
  .menu-title_hidden {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .menu-item_hidden {
    display: none;
  }
}
</style>
