const formatBytes = (bytes, decimals = 2) => {
  if (bytes === null || bytes === undefined) {
    return '';
  }
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
const getFilterOption = (roles = [], format = [], area = []) => ({
  params: {
    filter: {
      format: format,
      category: [],
      publisher: [],
      geometry: area,
      roles: roles
    }
  }
});

const IfFormatFilterFeatures = (format, datasetFeatures) => {
  if (!format || !format.length) {
    return datasetFeatures.features;
  }

  const formats = format.map(f => f.toLowerCase());
  return datasetFeatures.features.filter(feat => formats.includes(feat.format));
};
const waitSeconds = seconds => {
  return new Promise(resolve => {
    setTimeout(resolve, seconds * 1000);
  });
};

export { getFilterOption, IfFormatFilterFeatures, formatBytes, waitSeconds };
