import { formatBytes, waitSeconds } from '../helpers/store-helpers';
import { axiosClient } from '@/api/axiosClient';
import { status } from '@/config';

const state = {
  orders: [],
  savedOrderLoaded: false,
  selections: []
};
const order_path = '/order/';
const operations = {
  detail: '/detail',
  reorder: '/reorder',
  segments: '/segments',
  features: '/features',
  abort: '/abort'
};
const actions = {
  detail: async ({ commit }, uuid) => axiosClient.get(`${order_path}${uuid}${operations.detail}`),

  async detailsAsync({ commit, dispatch }, orderList) {
    /*new
		  processing
		  ready
		  error
		  abort
		  empty - es gibt kein download link */

    const details = await Promise.all(orderList.map(order => dispatch('detail', order.uuid)));
    const mappedDetails = details.map(detail => detail.data);
    const notReadyFeatures = mappedDetails.filter(
      item => item.status === status.new.name || item.status === status.processing.name
    );

    if (mappedDetails.length === 0) {
      return;
    }
    commit('UPDATE_ORDER_STATUS', mappedDetails);
    if (notReadyFeatures.length === 0) {
      return;
    }
    await waitSeconds(1);
    dispatch('detailsAsync', notReadyFeatures);
  },

  async order({ commit, dispatch }, opt) {
    const { payload, orderArea, selectedFeatures } = opt;

    const postRequests = payload.map(datasetItem => axiosClient.post(order_path, datasetItem));
    const res = await Promise.all(postRequests);

    const orderList = res.map(item => item.data);
    const orders = createOrders({ orderList, orderArea, selectedFeatures });
    commit('SET_ORDERS', orders);
    dispatch('detailsAsync', orders);
    return orderList;
  },
  async reorder({ commit, dispatch }, uuid) {
    const detail = await dispatch('detail', uuid);
    const isExpandable = detail.data.dataset.expandable;
    let requests = [
      axiosClient.put(`${order_path}${uuid}${operations.reorder}`),
      axiosClient.get(`${order_path}${uuid}${operations.segments}`)
    ];
    if (isExpandable) {
      requests.push(axiosClient.get(`${order_path}${uuid}${operations.features}`));
    }
    const res = await Promise.all(requests);

    const [orderList, segments, selectedFeatures] = res.map(item => item.data);
    const orders = createOrders({
      orderList: [orderList],
      orderArea: segments.map(item => item.title),
      selectedFeatures
    });

    commit('UPDATE_ORDER_BY_UUID', { uuid, orders });
    dispatch('detailsAsync', orders);
  },

  async abort({ commit }, uuid) {
    commit('ABORT_ORDER', uuid);
    await axiosClient.put(`${order_path}${uuid}${operations.abort}`);
  },
  async delete({ commit, dispatch }, uuid) {
    const detail = await dispatch('detail', uuid);
    const isReady = detail.data.status === 'ready';
    if (isReady) {
      await axiosClient.delete(`${order_path}${uuid}`);
    }
    commit('DELETE_ORDER', uuid);
  }
};

const mutations = {
  ABORT_ORDER(state, uuid) {
    let foundOrder = state.orders.find(ord => ord.uuid === uuid);
    if (foundOrder) {
      foundOrder.status = 'abort';
    }
  },
  DELETE_ORDER(state, uuid) {
    let index = state.orders.findIndex(ord => ord.uuid === uuid);
    if (index > -1) {
      state.orders.splice(index, 1);
    }
  },
  UPDATE_ORDER_STATUS(state, newOrders) {
    newOrders.forEach(order => {
      let foundOrder = state.orders.find(ord => ord.uuid === order.uuid);
      if (foundOrder && foundOrder.status !== 'abort') {
        foundOrder.status = order.status;
        foundOrder.result_file = order.result_file;
        foundOrder.downloadSize = formatBytes(order.processed_size);
      }
    });
  },
  UPDATE_ORDER_BY_UUID(state, opt) {
    const { uuid, orders } = opt;
    let index = state.orders.findIndex(oldOrder => {
      return oldOrder.uuid === uuid;
    });
    state.orders.splice(index, 1, orders[0]);
  },
  UPDATE_ORDER_BY_UUID1(state, datasetUUIDs) {
    console.log('state.orders before UPDATE ', state.orders.length);
    state.orders = state.orders.filter(oldOrder => {
      return !datasetUUIDs.includes(oldOrder.uuid);
    });
    console.log('state.orders after UPDATE ', state.orders.length);
  },

  SET_SAVED_ORDERS(state, orderList) {
    concatOrders(state.orders, orderList);
  },
  SET_ORDERS(state, orderList) {
    if (!state.orders.length) {
      concatOrders(orderList, orderList);
    } else {
      concatOrders(orderList, state.orders);
    }
  },
  SAVED_ORDER_LOADED(state, isDone) {
    state.savedOrderLoaded = isDone;
  }
};
function createOrders(opt) {
  const { orderList, orderArea, selectedFeatures = [] } = opt;
  const mapSelectedFeatures = item =>
    (Array.isArray(selectedFeatures) && selectedFeatures) ||
    selectedFeatures[item.dataset.id].filter(feat => feat != -1).map(feat => feat);
  return orderList.map((item, idx) => ({
    id: idx,
    status: item.status,
    //status: "error",
    name: item.dataset.title,
    orderArea: orderArea.join(),
    downloadSize: undefined,
    format: item.dataset.format,
    manage: 'new',
    features: mapSelectedFeatures(item),
    uuid: item.uuid,
    dataset: item.dataset.id,
    result_file: item.result_file,
    copyIcon: 'mdi-content-copy'
  }));
}
function concatOrders(oldOrders, newOrders) {
  if (state.orders.length > 0) {
    state.orders = oldOrders.concat(newOrders);
    return;
  }
  state.orders = newOrders;
}
export default {
  root: true,
  namespaced: true,
  state,
  actions,
  mutations
};
