<template>
  <v-menu-list :items="items" :closeOnContentClick="true">
    <v-icon>mdi-dots-vertical</v-icon>
    <template v-slot:list>
      <v-list dense flat color="grey lighten-2" max-height="200">
        <v-list-item-group color="geoportal" v-model="selected">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </v-menu-list>
</template>
<script>
import { cloneDeep } from 'lodash';
import VMenuList from '@/components/VMenuList.vue';
export default {
  components: {
    VMenuList
  },
  props: {
    value: {}
  },

  data() {
    return {
      selected: [],
      items: cloneDeep(this.value)
    };
  },

  watch: {
    selected(val) {
      if (!val) {
        return;
      }
      val.sel = true;
      this.items.filter(item => item.text !== val.text).forEach(item => (item.sel = false));
      this.$emit('input', this.items);
    }
  }
};
</script>
