const state = {
  snackbarColor: 'primary',
  snackbarText: '',
  progressLinear: false
};

const mutations = {
  SET_SNACKBAR_TEXT(state, text) {
    state.snackbarText = text;
  },
  SET_SNACKBAR_COLOR(state, color) {
    state.snackbarColor = color;
  },
  SET_SNACKBAR(state, opt) {
    let { text, color } = opt;
    state.snackbarText = text;
    state.snackbarColor = color;
  },
  START_LOADING(state) {
    state.progressLinear = true;
  },
  STOP_LOADING(state) {
    state.progressLinear = false;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
