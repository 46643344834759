<template>
  <v-text-field
    clearable
    hide-details
    placeholder="Suche"
    rounded
    dense
    single-line
    background-color="grey lighten-2"
    color="blue-grey"
    class="shrink"
    :disabled="disabled"
    v-model="textSearch"
    @input="textSearchChanged"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      textSearch: ''
    };
  },
  methods: {
    textSearchChanged(text) {
      this.$emit('textSearchChanged', text);
    },
    clear() {
      this.textSearch = '';
      this.$store.commit('dataSet/TEXTFILTER_SEARCH', '');
    }
  }
};
</script>
