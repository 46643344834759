import store from '@/store';

const requestHandler = request => {

  request.headers = { 'X-Auth-Token': store.state.auth.token };
  store.commit('notifications/START_LOADING');

  return request;
};
const requestErrorHandler = error => Promise.reject(error);

const responseHandler = response => {
  store.commit('notifications/STOP_LOADING');
  return response;
};
const getErrorData = errorData => {
  if (errorData.fieldErrors) {
    return { message: errorData.fieldErrors[0].message, code: errorData.code || ' ' };
  }
  return errorData;
};
const responseErrorHandler = error => {
  store.commit('notifications/STOP_LOADING');

  const errorData = error.response.data;
  const color = 'error';
  let { code, message } = getErrorData(errorData);

  if (!code || !message) {
    const { status } = error.response;
    message = 'Interner Server fehler. Versuchen Sie es später erneut.';
    code = status;
  }

  if (code === 401) {
    location.reload();
  }

  store.commit('notifications/SET_SNACKBAR', { text: message, color });
  console.dir(error);
  return Promise.reject(error);
};

export {
  requestHandler,
  requestErrorHandler,
  responseHandler,
  responseErrorHandler
};
