class User {
  constructor({ roles, segmentRoles, uid }) {
    this.roles = roles || [];
    this.segmentRoles = segmentRoles || [];
    this.uid = uid;
  }
  get userRoles() {
    return this.roles;
  }
  get userId() {
    return this.uid;
  }
  hasRequiredRoles(requiredRoles) {
    return requiredRoles.every(requiredRole => this.roles.some(userRole => userRole === requiredRole));
  }
  isAuth(app_roles) {
    return this.roles.some(role => app_roles.includes(role)) && this.segmentRoles.length > 0;
  }
}

let createUser = data => new User(data);
export { createUser, User };
