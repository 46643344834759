<template>
  <v-progress-linear
    :active="active"
    :color="color"
    indeterminate
  ></v-progress-linear>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'geoportal'
    }
  },
  computed: {
    active() {
      return this.$store.state.notifications.progressLinear;
    }
  }
};
</script>
